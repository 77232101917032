import { createStore } from "vuex"
import ApiService from '@/services/ApiService'

const store = new createStore({
    state: {
        pageData: {},
        sports: [],
        calendarDate: new Date(),
        currentSeason: "",
    },

    getters: {
        PAGE_DATA: state => {
            return state.pageData;
        },

        SPORTS: state => {
            return state.sports;
        },

        CALENDAR_DATE: state => {
            return state.calendarDate;
        },

        CURRENT_SEASON: state => {
            return state.currentSeason;
        }
    },

    actions: {
        GET_PAGE_DATA: async (context, path) => {
            let data = await ApiService.getPage(path);
            context.commit('SET_PAGE_DATA', data);
        },
        
        GET_SPORTS: async (context) => {
            let data = await ApiService.getSports();
            context.commit('SET_SPORTS', data.sports);
        },
    },

    mutations: {
        SET_PAGE_DATA: (state, payload) => {
            state.pageData = payload;
        },

        SET_SPORTS: (state, payload) => {
            state.sports = payload;
        },
        
        SET_CALENDAR_DATE: (state, payload) => {
            state.calendarDate = payload;
        },

        SET_CURRENT_SEASON: (state, payload) => {
            state.currentSeason = payload;
        }
    }
});

export default store;
<template>
    <details
        class="dropdown-widget dropdown-widget_without-marker flex flex_vertical"
    >
        <summary class="dropdown-widget__title">
            {{ country }}
        </summary>
        <RouterLink
            class="dropdown-widget__link sidebar__link"
            v-for="league in leaguesByGroup"
            :key="league.slug"
            :to="{
                name: 'leaguePage', 
                params: { 
                    sport: $route.params.sport ? $route.params.sport : sport,
                    league: league.slug
                }
            }"
        > 
            {{ league.name }}
        </RouterLink>
    </details>
</template>

<script>
    export default {
        name: "DropdownWidget",

        props: {
            sport: {
                type: String,
                required: false,
                default: ""
            },
            
            country: {
                type: String,
                required: true,
                default: ""
            },

            leaguesByGroup: {
                type: Array,
                required: true,
                default() {
                    return []
                }
            }
        }
    }
</script>

<style scoped> 
    .sidebar {
        display: none;
        margin-left: 1rem;
    }

    .dropdown-widget {
        margin-bottom: 0.5rem;
    }

    .dropdown-widget[open] {
        margin-bottom: 0;
    }

    .dropdown-widget_without-marker .dropdown-widget__title {
        list-style: none;
    }

    .dropdown-widget_without-marker::-webkit-details-marker {
        display: none;
    }

    .dropdown-widget__title {
        background-color: var(--surface-color);
        border-radius: 0.5rem;
        padding: 0.5rem;
        box-shadow: 0px 3px var(--box-shadow-blur) 0px var(--box-shadow-color);
        font-weight: 500;
        cursor: pointer;
    }

    .dropdown-widget__link {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0.75rem 0.5rem;
    }

    @media (min-width: 768px) {
        .sidebar {
            display: flex;
            flex-direction: column;
        }
    }
</style>
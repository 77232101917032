export const imageErrorHandler = {
    data() {
        return {
            baseImagesUrl: process.env.VUE_APP_HOST_IMAGES,
        }
    },
    
    methods: {
        handleImageError(event) {
            event.target.src = require('@/assets/not-found.webp');
        },
    }
}
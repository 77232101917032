<template>
    <footer id="footer" class="v-footer">
        <BaseLogo class="footer__v-logo" 
            logoPath="m_center_ochra.svg"
        />
        <p>Портал «Матч центр» — самые  свежие и актуальные события спорта, результаты и прогнозы на предстоящие события, спортивная аналитика.</p>
        <ul>
            <li>
                <RouterLink
                    class="footer__link"
                    :to="{name: 'PrivacyPolicy'}"
                >
                    Политика конфиденциальности
                </RouterLink>
            </li>
            <li>Беларусь, Минск, ул. Платонова 1Б</li>
            <li>+375 25 749-23-29</li>
            <li>© «Матч Центр» 2022</li>
        </ul>
    </footer>
</template>

<script>
    import BaseLogo from '@/components/base/BaseLogo.vue';

    export default {
        name: "TheFooter",

        components: {
            BaseLogo
        },

        data() {
            return {}
        }
    }

</script>

<style scoped>
    .v-footer {
        box-sizing: border-box;
        min-height: 4.5rem;
        padding: 2rem;
        background-color: var(--primary-color);
        color: var(--surface-color);
    }

    .footer__v-logo {
        display: block;
        margin: 0 0 2rem 0;
    }


    .footer__v-logo :deep(.v-logo) {
        max-height: 4rem;
    }

    .footer__link {
        color: var(--surface-color);
        text-decoration: underline;
    }
</style>
<template>
    <router-link :to="{name: 'mainPage'}">
        <img 
            :src="require('@/assets/' + logoPath)" 
            class="logo" 
            alt="logo match.by"
        />
    </router-link>
</template>

<script>
    export default {
        name: "BaseLogo",
        
        props: {
            logoPath: {
                type: String,
                default: ""
            }
        },
    }
</script>

<style scoped>
    .logo {
        height: 3rem;
        width: 3rem;
    }
</style>
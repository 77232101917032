import axios from 'axios'
import router from '@/router/router'

const BASE_URL = process.env.VUE_APP_HOST_BACK + '/api/';

class ApiService {
    constructor(router) {
        this.router = router;

        this.client = axios.create({
            baseURL: BASE_URL,
        });

        this.client.interceptors.request.use(
            (config) => {
                config.params = {...config.params, user_timestamp: Math.floor(Date.now() / 1000), time_offset: new Date().getTimezoneOffset() };

                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        this.client.interceptors.response.use(
            (response) => {
                return response.data;
            },
            (error) => {
                if (error.response) {
                    if(error.response.status == 404)
                        this.router.push('/page-not-found');
                    else
                        console.log(error.response.data, error.response.status, error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.toJSON());
                }
                return Promise.reject(error);
            },
        );
    }

    async getPage(slug) {
        return await this.client.get(slug);
    }

    async getSports() {
        return await this.client.get('sports');
    }

    async getSidebar(sportSlug) {
        return await this.client.get(`${sportSlug}/sidebar`);
    }

    async getMoreEvents(params) {
        return await this.client.get('events/more', params);
    }

    async getMoreLeagues(params) {
        return await this.client.get('leagues/more', params);
    }

    async getMoreCountries(params) {
        return await this.client.get('countries/more', params);
    }
}

export default new ApiService(router);
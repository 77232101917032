<template>
    <header class="v-header">
        <BaseLogo 
            logoPath="m_center_grey.svg"
        />
        <TheHeaderNav />
    </header>
</template>

<script>
    import BaseLogo from '@/components/base/BaseLogo.vue'
    import TheHeaderNav from '@/components/TheHeaderNav.vue'

    export default {
        name: "TheHeader",

        components: {
            BaseLogo,
            TheHeaderNav
        },
    }
   
</script>

<style scoped>
    .v-header {
        box-sizing: border-box;
        min-height: 4.5rem;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primary-color);
    }
</style>
import { createApp } from 'vue'
import App from './App.vue'

import store from './vuex/store'
import router from '../src/router/router'
import VueDragscroll from "vue-dragscroll"
import VueLazyLoad from 'vue3-lazyload'
import { imageErrorHandler } from '@/mixins/imageErrorHandler.js'

import "./assets/style/normalize.css"
import "./assets/style/common.css"
import "./assets/style/variables.css"
import "./assets/style/font.css"

const app = createApp(App)
     .use(store)
     .use(router)
     .mixin(imageErrorHandler)
     .use(VueDragscroll)
     .use(VueLazyLoad);
app.mount('#app');

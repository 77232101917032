<template>
    <Slide 
        :isOpen="isOpen" 
        @closeMenu="isOpen" 
        :closeOnNavigation="true" 
        right
        class="v-nav"
    >
        <ul>
            <li 
                class="v-nav__item" 
                v-for="sport in SPORTS"
                :key="sport.sport"
            >
                <router-link 
                    :to="{
                        name: 'sportPage', 
                        params: { sport: sport.slug }
                    }"
                >
                    {{ sport.nameRu }}
                </router-link>
            </li>
        </ul> 
    </Slide>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { Slide } from 'vue3-burger-menu'
 
    export default {
        name: "TheHeaderNav",

        components: {
            Slide
        },

        data() {
            return {
                isOpen: false
            }
        },

        computed: {
            ...mapGetters([
                'SPORTS',
                'SPORT_BY_INDEX'
            ])
        },
    }
</script>

<style scoped>
    .bm-item-list ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .v-nav__item a {
        display: block;
        color: var(--surface-color);
        padding: 1rem 1.5rem;
    }

    :deep(.bm-burger-button ) {
        right: 2rem;
        left: auto;
        top: 1.45rem;
        transition: all 0.3s ease-in-out;
    }

    :deep(.bm-cross-button) {
        transition: all 0.3s ease-in-out;
    }

    :deep(.bm-burger-bars) {
        background-color: var(--surface-color);
    }

    :deep(.line-style) {
        height: 3px;
    }

    :deep(.bm-menu) {
        background-color: var(--primary-color);
    }

    :deep(.cross-style) {
        top: 1.45rem;
        right: 2rem;
    }

    :deep(.bm-cross) {
        height: 30px !important;
        right: 50%;
        background-color: var(--surface-color);
    }

    @media (min-width: 768px) {
        :deep(.bm-burger-button) {
            display: none;
        }

        :deep(.bm-menu) {
            position: relative;
            height: auto;
            width: auto !important;
            background-color: transparent;
            padding: 0;
        }

        :deep(.bm-item-list) {
            margin: 0;
        }

        :deep(.bm-item-list>*) {
            padding: 0;
        }

        :deep(.bm-item-list ul) {
            flex-direction: row;
        }

        :deep(.bm-cross-button) {
            display: none;
        }
    }
</style>
<template>
    <div 
        class="sidebar"
        v-if="isLoaded"
    >
        <template v-if="sidebarData.leagues">
            <p class="sidebar_title">ТОР лиги</p>
            <router-link 
                v-for="league in sidebarData.leagues"
                :key="league.slug"
                class="sidebar__link"
                :to="{
                    name: 'leaguePage', 
                    params: {
                        sport: league.sport.slug,
                        league: league.slug,
                    }
                }"
            >
                {{ league.name }}
            </router-link>
        </template>

        <p class="sidebar_title">Страны</p>
        
        <DropdownWidget
            v-for="group in sidebarData.sports ? sidebarData.sports : sidebarData.countries"
            :key="group.slug"
            :sport="group.slug"
            :country="group.nameRu ? group.nameRu : group.nameEn"
            :leaguesByGroup="group.leagues"
        />

        <BaseButton
            class="sidebar__show-more-btn"
            v-if="sidebarData.isMoreCountries"
            @click="loadMoreCountries()"
        >
            Смотреть ещё
        </BaseButton>
    </div>
</template>

<script>
    import DropdownWidget from '@/components/DropdownWidget.vue'
    import BaseButton from '@/components/base/BaseButton.vue'
	import ApiService from '@/services/ApiService'

    export default {
        name: "TheSidebar",

        components: {
            DropdownWidget,
            BaseButton
        },

        data() {
            return {
                isLoaded: false,
                sport: "",
                sidebarData: {},
            }
        },

        watch: {
            async $route (to) {
                let sportSlug = to.params.sport ? to.params.sport : '';
                this.sidebarData = await ApiService.getSidebar(sportSlug);
            }
        },

        async created() {
            let sportSlug = this.$route.params.sport ? this.$route.params.sport : '';
            this.sidebarData = await ApiService.getSidebar(sportSlug);
            this.isLoaded = true;
        },

        methods: {
            async loadMoreCountries() {
                let params = {
                    sportSlug: this.$route.params.sport,
                    offset: this.sidebarData.countries.length,
                }

                let { countries, isMoreCountries } = await ApiService.getMoreCountries({ params });

                this.sidebarData.countries.push(...countries);
                this.sidebarData.isMoreCountries = isMoreCountries;
            }
        },
    }
</script>

<style scoped> 
    .sidebar {
        display: none;
        margin-left: 1rem;
    }

    .sidebar_title {
        color: var(--primary-color);
        margin-bottom: 0.75rem;
        font-size: 1.25rem;
        font-weight: 700;
    }

    :deep(.sidebar__link) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0.55rem 0.25rem;
        font-weight: 500;
    }

    :deep(.sidebar__link):hover {
        font-weight: 600;
    }

    :deep(.sidebar__link.router-link-active) {
        font-weight: 700;
        color: var(--primary-color);
    }

    .sidebar__show-more-btn {
        width: 100%;
    }

    .leagues-by-countries {
        max-height: 80vh;
        overflow: hidden;
    }

    @media (min-width: 768px) {
        .sidebar {
            display: flex;
            flex-direction: column;
        }
    }
</style>
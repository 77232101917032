import { createRouter, createWebHistory } from 'vue-router'

const GroupedEventsPage = () => import(/* webpackChunkName: "GroupedEventsPage" */ "@/components/pages/GroupedEventsPage.vue")
const GroupedEventsList = () => import(/* webpackChunkName: "GroupedEventsList" */ "@/components/GroupedEventsList.vue")
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */ "@/components/pages/PrivacyPolicy.vue")
const PageNotFound = () => import(/* webpackChunkName: "PageNotFound" */ "@/components/exceptions/PageNotFound.vue")
const PageWithTabs = () => import(/* webpackChunkName: "PageWithTabs" */ "@/components/pages/PageWithTabs.vue")

const TabOverview = () => import(/* webpackChunkName: "TabOverview" */ "@/components/TabOverview.vue")
const TabOverviewEventsPage = () => import(/* webpackChunkName: "TabOverviewEventsPage" */ "@/components/TabOverviewEventsPage.vue")
const TabEvents = () => import(/* webpackChunkName: "TabEvents" */ "@/components/TabEvents.vue")
const TabStandings = () => import(/* webpackChunkName: "TabStandings" */ "@/components/TabStandings.vue")
const TabSquad = () => import(/* webpackChunkName: "TabSquad" */ "@/components/TabSquad.vue")
const TabH2H = () => import(/* webpackChunkName: "TabH2H" */ "@/components/TabH2H.vue")
const TabStatistics = () => import(/* webpackChunkName: "TabStatistics" */ "@/components/TabStatistics.vue")


let routes = [
    {
        path: '',
        component: GroupedEventsPage,
        children: [{
            path: '',
            name: 'mainPage',
            component: GroupedEventsList,
            meta: { 
                title: `Match.by | Новости, расписание, результаты спортивных матчей по популярным видам спорта`, 
                description: `Следите за свежими новостями спорта, смотрите расписание, результаты, статистику и прямые трансляции спортивных матчей на match.by!`
            },
        },
        {
            path: ':sport',
            children: [{
                path: '',
                name: 'sportPage',
                component: GroupedEventsList,
            }, {
                path: ':date',
                name: 'sportPageByDate',
                component: GroupedEventsList,
            }]
        }]
    },
    {
        path: '/:sport/leagues/:league',
        component: PageWithTabs,
        children: [{
            path: ':season?',
            name: 'leaguePage',
            component: TabOverview,
        },
        {
            path: ':season?/events',
            name: 'leaguePageEvents',
            component: TabEvents,
        },
        {
            path: ':season?/standings',
            name: 'leaguePageStandings',
            component: TabStandings,
        }]
    },
    {
        path: '/:sport/teams/:team',
        component: PageWithTabs,
        children: [{
            path: '',
            name: 'teamPage',
            component: TabOverview,
        },
        {
            path: 'events',
            name: 'teamPageEvents',
            component: TabEvents,
        },
        {
            path: 'squad',
            name: 'teamPageSquad',
            component: TabSquad,
        }]
    },
    {
        path: '/:sport/events/:event',
        component: PageWithTabs,
        children: [{
            path: '',
            name: 'eventPage',
            component: TabOverviewEventsPage,
        },
        {
            path: 'h2h',
            name: 'eventPageH2H',
            component: TabH2H,
        },
        {
            path: 'statistics',
            name: 'eventPageStatistics',
            component: TabStatistics,
        }]
    },
    {
        path:'/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: { 
            title: `Match.by | Политика конфиденциальности`, 
            description: `Ознакомьтесь с политикой конфиденциальности match.by`
        },
    },
    {
        path: '/page-not-found',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: { 
            title: `Ошибка 404 - Страница не найдена`, 
            description: `Страницы с таким адресом не существует`
        },
    },
    {
        path: "/:pathMatch(.*)*", 
        redirect: '/page-not-found',
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if(to.meta.title != undefined && to.meta.title != undefined) {
        document.title = to.meta.title
    
        const description = to.meta.description
        const descriptionTag = document.querySelector('meta[name="description"]')
    
        if (description) 
            descriptionTag.setAttribute('content', description)
        else 
            descriptionTag.removeAttribute('content')
    }

    next();
});

export default router;
<template>
    <button class="button">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "BaseButton",
    }
</script>

<style scoped>
    .button {
        cursor: pointer;
        background-color: var(--primary-color);
        color: var(--surface-color);
        font-weight: 600;
        border: 0;
        border-radius: 0.5rem;
        padding: 0.75rem 1.5rem;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .button:hover {
        transition: all 0.1s ease-in;
        box-shadow: 0px 3px var(--box-shadow-blur) 0px var(--box-shadow-hover-color);
        font-weight: 700;
    }
</style>
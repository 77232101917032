<template>
	<the-header />
	<the-sidebar v-if="$route.fullPath != '/page-not-found'"/>
	<div class="main-content">
		<router-view />
	</div>
	<div class="scroll-to-top">
		<a href="#" @click="scrollToTop">↑</a>
	</div>
	<the-footer />
</template>

<script>
	import TheHeader from '@/components/TheHeader.vue'
	import TheFooter from '@/components/TheFooter.vue'
	import TheSidebar from '@/components/TheSidebar.vue'
	import { mapActions, mapMutations } from 'vuex'

	export default {
		name: "App",

		components: {
			TheHeader,
			TheSidebar,
			TheFooter
		},
		
		watch: {
            $route(to, from) {
                if(from.name == 'sportPageByDate' && to.name != 'sportPageByDate')
                    this.SET_CALENDAR_DATE(new Date());
            }
        },

		created() {
            this.GET_SPORTS();
        },

        methods: {
            ...mapActions(['GET_SPORTS']),
            ...mapMutations(['SET_CALENDAR_DATE']),

			scrollToTop(e) {
				e.preventDefault();
				window.scrollTo({top: 0, behavior: 'smooth'});
			},
        }
	}
</script>

<style scoped>
	.main-content {
		margin: 1rem;
	}
	
	.scroll-to-top {
		display: flex;
		justify-content: center;
		position: fixed;
		bottom: 2rem;
		right: 2rem;
		background-color: var(--primary-color);
		border: 1px solid var(--surface-color);
		border-radius: 0.5rem;
	}

	.scroll-to-top a {
		font-size: 2rem;
		font-weight: 500;
		color: var(--surface-color);
		padding: 0.5rem 1rem;
	}

	@media (min-width: 768px) {
		.main-content {
			margin: 0 1rem 0 0;
		}
	}
</style>